import React, { useRef, useCallback, useEffect, useState, useLayoutEffect, useMemo } from "react";
import Link from "next/link";
import useRefMounted from "@/hooks/useRefMounted";

import { useInView } from "react-intersection-observer";

import clamp from "lodash/clamp";

import gsap from "@/helpers/gsap";
import { Power2 } from "gsap";
import ScrollContainer from "react-indiana-drag-scroll";

import CrossIcon from "@/images/icons/cross.svg";

import trans from "@/helpers/trans";
import onWindowResize from "@/helpers/onWindowResize";

import useAuthStore from "@/stores/auth";
import useUserCacheStore from "@/stores/userCache";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import cloneDeep from "lodash/cloneDeep";
import { apiUpdateQuiz } from "@/api/quiz";
import { CATEGORIES } from "@/app-constants.mjs";
import QuizItem from "./QuizItem";

const NUMBER_OF_INITIAL_IN_VIEW_ITEMS = 6;

export default function QuizScrollContainer({
	className,
	setShowCreatePage = null,
	isMyLibrary = false,
	showCreateQuiz = false,
	showMyLibrary = false,
	initialInView = true,
	fallbackInView = true,
	hideQuizItemNew = false,
	threshold = 0,
	total,
	sectionId,
	onTogglePreview,
	...props
}) {
	const [mounted] = useRefMounted();

	const { ref, inView } = useInView({
		initialInView,
		fallbackInView,
		threshold,
	});

	const showEditorials = !isMyLibrary || (isMyLibrary && showMyLibrary);

	const [quizzes, setQuizzes] = useState(props.quizzes);
	useEffect(() => void setQuizzes(props.quizzes), [props.quizzes]);

	const [title, setTitle] = useState(props.title || "");
	useEffect(() => void setTitle(props.title), [props.title]);

	const titleLink = useMemo(() => {
		if (props.titleLink) {
			return props.titleLink;
		} else if (props.section?.type === "published" && props.category) {
			return `/${props.category.slug}/${props.category.tag.name}/?sort=publishedAt&order=desc`;
		} else if (props.section?.type === "tag" || props.section?.type === "tag2") {
			if (props.category) {
				return `/${props.category.slug}/${props.section.parameter}`;
			} else {
				const category = CATEGORIES.find((category) => category.tag === props.section?.parameter);
				if (category) {
					return `/${category.slug}/`;
				} else {
					return `/tag/${props.section.parameter}/`;
				}
			}
		} else {
			return null;
		}
	}, [props.category, props.section?.parameter, props.section?.type, props.titleLink]);

	const quizzesPlayed = useUserCacheStore((state) => state.quizzesPlayed);

	const scrollContainerRef = useRef(null);
	const scrollContainerTweenRef = useRef(null);
	const scrollInnerRef = useRef(null);
	const scrollButtonLeftRef = useRef(null);
	const scrollButtonLeftTweenRef = useRef(null);
	const scrollButtonRightRef = useRef(null);
	const scrollButtonRightTweenRef = useRef(null);

	const onStartScroll = useCallback(({ external }) => {
		if (!external && scrollContainerTweenRef.current) {
			scrollContainerTweenRef.current.kill();
			scrollContainerTweenRef.current = null;
		}
	}, []);

	const resetButtons = useCallback(() => {
		if (scrollContainerRef.current) {
			if (scrollButtonLeftTweenRef.current) {
				scrollButtonLeftTweenRef.current.kill();
				scrollButtonLeftTweenRef.current = null;
			}

			if (scrollButtonRightTweenRef.current) {
				scrollButtonRightTweenRef.current.kill();
				scrollButtonRightTweenRef.current = null;
			}

			const { offsetWidth, scrollLeft, scrollWidth } = scrollContainerRef.current;

			const leftButtonVisible = scrollLeft !== 0;
			const rightButtonVisible = scrollLeft !== scrollWidth - offsetWidth;

			if (scrollButtonLeftRef.current) {
				gsap.set(scrollButtonLeftRef.current, {
					xPercent: leftButtonVisible ? 0 : -100,
					visibility: "visible",
				});
			}

			if (scrollButtonRightRef.current) {
				gsap.set(scrollButtonRightRef.current, {
					xPercent: rightButtonVisible ? 0 : 100,
					visibility: "visible",
				});
			}
		}
	}, []);

	const onEndScroll = useCallback(() => {
		if (scrollContainerRef.current) {
			const { offsetWidth, scrollLeft, scrollWidth } = scrollContainerRef.current;

			const leftButtonVisible = scrollLeft !== 0;
			const rightButtonVisible = scrollLeft < scrollWidth - offsetWidth;

			if (scrollButtonLeftTweenRef.current) {
				scrollButtonLeftTweenRef.current.kill();
				scrollButtonLeftTweenRef.current = null;
			}

			if (scrollButtonLeftRef.current) {
				scrollButtonLeftTweenRef.current = gsap.to(scrollButtonLeftRef.current, {
					xPercent: leftButtonVisible ? 0 : -100,
					duration: 0.25,
					ease: Power2.easeOut,
					onComplete: () => void (scrollButtonLeftTweenRef.current = null),
				});
			}

			if (scrollButtonRightTweenRef.current) {
				scrollButtonRightTweenRef.current.kill();
				scrollButtonRightTweenRef.current = null;
			}

			if (scrollButtonRightRef.current) {
				scrollButtonRightTweenRef.current = gsap.to(scrollButtonRightRef.current, {
					xPercent: rightButtonVisible ? 0 : 100,
					duration: 0.25,
					ease: Power2.easeOut,
					onComplete: () => void (scrollButtonRightTweenRef.current = null),
				});
			}
		}
	}, []);

	const scroll = useCallback((direction = 1) => {
		if (scrollContainerRef.current) {
			const { offsetWidth, scrollLeft, scrollWidth } = scrollContainerRef.current;

			if (scrollContainerTweenRef.current) {
				scrollContainerTweenRef.current.kill();
				scrollContainerTweenRef.current = null;
			}

			scrollContainerTweenRef.current = gsap.to(scrollContainerRef.current, {
				scrollLeft: clamp(Math.floor(scrollLeft + offsetWidth * direction), 0, scrollWidth - offsetWidth),
				duration: 0.5,
				ease: Power2.easeOut,
				onComplete: () => void (scrollContainerTweenRef.current = null),
			});
		}
	}, []);

	useEffect(() => {
		if (scrollContainerRef.current) {
			return onWindowResize(onEndScroll);
		}
	}, [onEndScroll]);

	useEffect(() => void resetButtons(), [quizzes, resetButtons, inView]);

	useEffect(() => {
		if (scrollContainerRef.current) {
			gsap.set(scrollContainerRef.current, { scrollLeft: 0 });
		}

		return () => {
			if (scrollContainerTweenRef.current) {
				scrollContainerTweenRef.current.kill();
				scrollContainerTweenRef.current = null;
			}

			if (scrollButtonLeftTweenRef.current) {
				scrollButtonLeftTweenRef.current.kill();
				scrollButtonLeftTweenRef.current = null;
			}

			if (scrollButtonRightTweenRef.current) {
				scrollButtonRightTweenRef.current.kill();
				scrollButtonRightTweenRef.current = null;
			}
		};
	}, [quizzes]);

	const onClickLeft = useCallback(() => void scroll(-1), [scroll]);
	const onClickRight = useCallback(() => void scroll(1), [scroll]);

	const adminFeaturesEnabled = useAuthStore((state) => state.adminFeaturesEnabled);

	return (
		<div className={tailwindCascade("flex", "flex-col", className)}>
			<div
				className={`flex flex-row items-center justify-start pt-2 space-x-8 ${
					isMyLibrary && !showMyLibrary ? "pb-4" : "pb-0"
				}`}
			>
				{title && (
					<h2 className="small-mobile:text-xl font-sans text-base font-black tracking-normal text-black">
						{titleLink && isMyLibrary ? (
							<div className="flex flex-row items-center space-x-2">
								<span className="whitespace-nowrap capitalize">{title}</span>
								<span className="text-blue small-mobile:text-base whitespace-nowrap text-sm">
									<Link legacyBehavior href={titleLink}>
										<a className="underline">{total ? trans("See all %s", total) : ""}</a>
									</Link>
								</span>
								<span className="text-blue small-mobile:text-base pl-2 text-sm">
									<button
										type="button"
										className="font-black"
										onClick={() => {
											if (onTogglePreview) {
												onTogglePreview();
											}
										}}
									>
										<span className="small-mobile:inline whitespace-nowrap hidden underline">
											{showMyLibrary ? trans("Hide my quizzes") : trans("Show my quizzes")}
										</span>
										<span className="small-mobile:hidden whitespace-nowrap inline underline">
											{showMyLibrary ? trans("Hide") : trans("Show")}
										</span>
									</button>
								</span>
							</div>
						) : titleLink && (props.section?.type === "tag" || props.section?.type === "tag2") ? (
							<div className="flex flex-row items-center space-x-2">
								<span className="whitespace-nowrap capitalize">{title}</span>
								<span className="text-blue small-mobile:text-base whitespace-nowrap text-sm">
									<Link legacyBehavior href={titleLink}>
										<a className="underline">
											{trans(
												"See all %s",
												props.section?.total ? `(${props.section?.total})` : ""
											)}
										</a>
									</Link>
								</span>
							</div>
						) : titleLink ? (
							<Link legacyBehavior href={titleLink}>
								<a className="hover:underline whitespace-nowrap">{title} »</a>
							</Link>
						) : (
							<span className="whitespace-nowrap">{title}</span>
						)}
					</h2>
				)}
			</div>

			{showEditorials && (
				<div
					ref={ref}
					className="h-[calc(25vw+3.875rem)] sm:h-[calc(18.75vw+3.875rem)] md:h-[13.575rem] lg:h-[14.075rem] xl:h-[14.5rem] 2xl:h-[16.5rem] relative flex flex-col overflow-hidden"
				>
					{inView ? (
						<>
							<ScrollContainer
								className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white-50 w-[calc(100%+0.5rem)] sm:w-[calc(100%+1rem)] overflow-y-hidden"
								vertical={false}
								horizontal={true}
								hideScrollbars={true}
								innerRef={scrollContainerRef}
								onStartScroll={onStartScroll}
								onEndScroll={onEndScroll}
							>
								<div ref={scrollInnerRef} className="flex flex-row flex-shrink-0 pt-2 transform">
									{showCreateQuiz && isMyLibrary && (
										<QuizItem
											quiz={null}
											createOwn={true}
											className="sm:pr-4 sm:w-1/4 md:w-1/4 lg:w-1/5 xl:w-1/6 relative flex flex-col flex-shrink-0 w-1/3 pr-2"
										/>
									)}
									{(quizzes || []).map((quiz, i, quizzes) => (
										<QuizItem
											key={quiz.id}
											quiz={quiz}
											isPlayed={mounted && quizzesPlayed.has(quiz.id)}
											isFirst={i === 0}
											isLast={i === quizzes.length - 1}
											setShowCreatePage={setShowCreatePage}
											isMyLibraryEditorial={isMyLibrary}
											initialInView={i < NUMBER_OF_INITIAL_IN_VIEW_ITEMS}
											hideNew={hideQuizItemNew}
											className="sm:pr-4 sm:w-1/4 md:w-1/4 lg:w-1/5 xl:w-1/6 relative flex flex-col flex-shrink-0 w-1/3 pr-2"
										>
											{mounted && adminFeaturesEnabled && (
												<div className="z-5 right-4 md:flex bg-pink-light bg-opacity-20 border-pink-light absolute bottom-0 hidden font-bold border-4 rounded-lg">
													<button
														className="disabled:opacity-25 p-1"
														onClick={() => {
															apiUpdateQuiz(quizzes[i].id, { staffRating: -1 })
																.then(() => {
																	const newQuizzes = cloneDeep(quizzes);
																	newQuizzes.splice(i, 1);
																	setQuizzes(newQuizzes);
																})
																.catch((err) => void window.alert(err));
														}}
														title="Exclude from ALL editorials"
													>
														<CrossIcon className="w-4 h-4 text-black" />
													</button>
												</div>
											)}
										</QuizItem>
									))}
								</div>
							</ScrollContainer>
							<button
								ref={scrollButtonLeftRef}
								onClick={onClickLeft}
								className="group top-2 md:w-16 absolute left-0 invisible w-12 h-0 pb-[24%] sm:pb-[17.33102%] md:pb-[8.0625rem] lg:pb-[8.75rem] xl:pb-[9.125rem] 2xl:pb-[11.125rem] transform"
							>
								<div className="bg-white-50 left-1/2 top-1/2 group-hover:bg-white md:w-16 md:h-16 absolute w-12 h-12 transition-colors transform -translate-x-1/2 -translate-y-1/2 rounded-full">
									<img
										src="/images/icons/chevron-left-multicolor.svg"
										width="48"
										height="48"
										alt={trans("Left")}
										draggable={false}
										className="md:w-16 md:h-16 relative w-12 h-12"
									/>
								</div>
							</button>
							<button
								ref={scrollButtonRightRef}
								onClick={onClickRight}
								className="group top-2 md:w-16 absolute right-0 invisible w-12 h-0 pb-[24%] sm:pb-[17.33102%] md:pb-[8.0625rem] lg:pb-[8.75rem] xl:pb-[9.125rem] 2xl:pb-[11.125rem] transform"
							>
								<div className="bg-white-50 left-1/2 top-1/2 group-hover:bg-white md:w-16 md:h-16 absolute w-12 h-12 transition-colors transform -translate-x-1/2 -translate-y-1/2 rounded-full">
									<img
										src="/images/icons/chevron-right-multicolor.svg"
										width="48"
										height="48"
										alt={trans("Right")}
										draggable={false}
										className="md:w-16 md:h-16 relative w-12 h-12"
									/>
								</div>
							</button>
						</>
					) : (
						<div className="bg-white-black-10 w-full h-[calc(25vw-0.75rem)] sm:h-[calc(18.75vw-1rem)] md:h-[8.0625rem] lg:h-[8.75rem] xl:h-[9.125rem] 2xl:h-[11.125rem] rounded-xl mt-2" />
					)}
				</div>
			)}
		</div>
	);
}
