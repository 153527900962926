import React, { useRef, useCallback, useEffect, useState, useLayoutEffect, useMemo } from "react";
import Link from "next/link";

import ImagePlaceHolder from "@/images/icons/icon-photos.svg";

import CoverImage from "@/components/CoverImage";
import Button from "@/components/interactives/Button";

import trans from "@/helpers/trans";

import usePlayQuiz from "@/hooks/usePlayQuiz";

import useAuthStore from "@/stores/auth";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import { getDifficulty } from "@/helpers/difficulty";
import { useRouter } from "next/router";

import IconSlides from "@/images/icons/icon-slides.svg";
import getQuizName from "@/helpers/getQuizName";
import formatDate from "@/helpers/formatDate";
import { secondsToText } from "@/helpers/answerTimes";

function calculateCoverImageSize(width, minWidth = null) {
	return {
		width: ((width - 1) >> 1) << 1,
		height: ((((width - 1) * 3) / 4) >> 1) << 1,
		minWidth,
	};
}

export default function QuizItem({
	className,
	quiz,
	isPlayed = false,
	isFirst = false,
	isLast = false,
	setShowCreatePage = null,
	isMyLibraryEditorial = false,
	isMyLibraryPage = false,
	createOwn = false,
	hideNew = false,
	...props
}) {
	const router = useRouter();

	const ownerName = quiz?.owner?.name;
	const quizName = getQuizName(quiz, createOwn ? "" : null);
	const quizMedia = quiz?.media;
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const isGuestUser = useAuthStore((state) => state.isGuestUser);

	const playDurationAvgString = useMemo(
		() => secondsToText(quiz?.playDurationAvg, true, true),
		[quiz?.playDurationAvg]
	);

	if (isFirst) {
		isLast = false;
	}

	// React Hydration Error fix!
	const [isPlayedInternal, setIsPlayedInternal] = useState(false);
	useEffect(() => void setIsPlayedInternal(isPlayed), [isPlayed]);

	const { isEasy, isHard } = useMemo(() => getDifficulty(quiz, quiz?.slideCount), [quiz]);

	const editUrl = `/edit/${quiz?.id ? `${quiz?.id}/` : ""}`;
	const overviewUrl = `/${quiz?.id ? `${quiz?.id}/` : ""}`;
	const userUrl = `/@${quiz?.owner?.name ? `${quiz.owner.name.toLowerCase()}/` : ""}`;

	const hasQuizMedia = quizMedia && quizMedia.source;

	const [isLoaded, setIsLoaded] = useState(hasQuizMedia ? false : true);
	const [fadeInElement, _setFadeInElement] = useState(null);
	const [fadedIn, setFadedIn] = useState(false);
	const setFadeInElement = useCallback((element) => void _setFadeInElement(element), []);
	const playQuiz = usePlayQuiz(quiz?.id);

	const fadeIntTimeoutRef = useRef(null);
	const readyInTimeoutRef = useRef(null);

	useEffect(() => {
		if (!fadeIntTimeoutRef.current && isLoaded && fadeInElement) {
			const fadeInTimeout = (fadeIntTimeoutRef.current = setTimeout(
				() => void (fadeInElement.style.opacity = 0),
				0
			));
			const readyInTimeout = (readyInTimeoutRef.current = setTimeout(() => void setFadedIn(true), 300 * 2));
			return () => {
				clearTimeout(fadeInTimeout);
				fadeIntTimeoutRef.current = null;

				clearTimeout(readyInTimeout);
				readyInTimeoutRef.current = null;
			};
		}
	}, [isLoaded, fadeInElement]);

	const BACKGROUND_STYLE = {
		backgroundImage: "url('/images/backgrounds/background-create-quiz.svg')",
		backgroundPosition: "50% 50%",
		backgroundSize: "84px auto",
	};

	return (
		<div data-last={isLast} className={tailwindCascade("w-full", className)}>
			<div className="flex flex-col gap-2">
				<div className="pb-4/3 relative w-full">
					<div className="md:rounded-xl z-1 absolute top-0 left-0 block w-full h-full overflow-hidden rounded-lg">
						{!fadedIn && (
							<div
								ref={setFadeInElement}
								className="bg-white-black-10 z-1 absolute top-0 left-0 w-full h-full transition-opacity duration-300 opacity-100 pointer-events-none"
							/>
						)}
						<div className="absolute top-0 left-0 z-0 w-full h-full">
							{hasQuizMedia && (
								<CoverImage
									media={quizMedia}
									sizes={[
										calculateCoverImageSize(237, 1536),
										calculateCoverImageSize(194, 1280),
										calculateCoverImageSize(185, 1024),
										calculateCoverImageSize(172, 768),
										calculateCoverImageSize(109),
									]}
									onLoad={() => void setIsLoaded(true)}
									alt={quizName}
								/>
							)}
							{!hasQuizMedia && !createOwn && (
								<div className="bg-black-10 flex flex-col items-center justify-center w-full h-full">
									<ImagePlaceHolder className="text-opacity-20 bg-black-10 w-24 h-24 mx-auto text-black" />
								</div>
							)}
							{createOwn && (
								<div className="bg-petrol-lighter w-full h-full" style={BACKGROUND_STYLE}></div>
							)}
						</div>
						<div
							className={`z-2 absolute top-0 left-0 w-full h-full ${isLoaded ? "visible" : "invisible"}`}
						>
							<div
								className={`z-3 absolute inset-0 flex flex-row items-center justify-center transition-opacity duration-300 ease-in-out ${
									createOwn
										? "opacity-100 bg-transparent"
										: "bg-black-50 opacity-0 hover:opacity-100 "
								}`}
							>
								{!createOwn && (
									<Button
										onClick={() => {
											if (isMyLibraryPage) {
												router.push(`/${quiz?.id ? `${quiz.id}/` : ""}`);
											} else {
												if (isAuthenticated || isGuestUser) {
													playQuiz();
												} else {
													if (setShowCreatePage) {
														setShowCreatePage(true, quiz?.id, quiz?.generated);
													}
												}
											}
										}}
										elementType="a"
										border={3}
										color="answer3"
										className="md:h-14 md:w-32 lg:w-40 md:text-base w-24 h-10 p-0 text-sm font-bold"
									>
										{isMyLibraryPage ? (
											trans("View")
										) : (
											<>
												<span className="md:inline hidden">{trans("Play Now")}</span>
												<span className="md:hidden inline">{trans("Play")}</span>
											</>
										)}
									</Button>
								)}
								{createOwn && (
									<div className="px-1">
										<Button
											color="answer2"
											border={true}
											className="lg:px-4 lg:text-lg px-2 text-base leading-none text-black"
											onClick={() => {
												router.push("/edit/new/");
											}}
										>
											<>
												<span className="md:inline hidden">{trans("Create Quiz")}</span>
												<span className="md:hidden inline">{trans("Create")}</span>
											</>
										</Button>
									</div>
								)}
							</div>
						</div>
						{isPlayedInternal && (
							<div className="text-white text-xsm md:text-sm text-opacity-90 bg-opacity-70 rounded-br-md z-3 absolute left-0 top-0 pl-2 pr-2 md:pr-3 py-1.5 leading-none font-bold bg-black select-none">
								<span className="inline-block">✓</span>
								<span className="md:inline-block hidden pl-1">{trans("Played")}</span>
							</div>
						)}
						{(quiz?.generated || isHard || isEasy) && (
							<div className="left-0 w-full bottom-1.5 absolute h-5">
								<div className="relative flex flex-row w-full h-full px-1.5 md:gap-x-2 gap-x-1">
									{isEasy && (
										<img
											src="/images/icons/icon-label-easy.svg"
											alt={trans("Easy")}
											className="block w-auto h-full"
										/>
									)}
									{isHard && (
										<img
											src="/images/icons/icon-label-hard.svg"
											alt={trans("Hard")}
											className="block w-auto h-full"
										/>
									)}
									{quiz?.generated && (
										<img
											src="/images/icons/icon-label-ai-generated.svg"
											alt={trans("AI Generated")}
											className="lg:block hidden w-auto h-full"
										/>
									)}
									{quiz?.generated && (
										<img
											src="/images/icons/icon-label-ai-generated-small.svg"
											alt={trans("AI Generated")}
											className="lg:hidden block w-auto h-full"
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="font-roboto rounded-b-xl flex flex-col w-full text-xsm bg-transparent space-y-0.5">
					<div className="pb-1">
						<div className="line-clamp-2 md:text-base md:leading-none md:max-h-10 max-h-8 overflow-hidden font-sans text-sm font-black leading-none tracking-normal text-black">
							<Link legacyBehavior href={overviewUrl} prefetch={false}>
								<a className="hover:underline hover:text-black" title={quizName}>
									{quizName}
								</a>
							</Link>
						</div>
					</div>
					<div className="flex flex-row flex-shrink-0 space-x-1 leading-4">
						{quiz?.ratingAvg ? (
							<>
								<div className="whitespace-nowrap text-gold font-bold">
									{quiz?.ratingAvg.toFixed(1)}
								</div>
								<div className="pr-2">
									<img
										src="/images/icons/star-color-multicolor.svg"
										width="16"
										height="16"
										alt={trans("Rating")}
										draggable={false}
										className="opacity-80 w-4 h-4"
									/>
								</div>
							</>
						) : hideNew ? null : (
							<div className="whitespace-nowrap text-gold pr-2 font-bold">
								{createOwn ? "" : trans("NEW!")}
							</div>
						)}
						{!isMyLibraryPage && (
							<div className="whitespace-nowrap overflow-ellipsis text-opacity-60 md:flex-row md:space-x-3 flex flex-col space-x-2 overflow-hidden font-bold text-black">
								{!isMyLibraryEditorial && ownerName && (
									<Link legacyBehavior href={userUrl} prefetch={false}>
										<a
											className="hover:underline hover:text-black"
											title={trans("Quiz by %s", ownerName)}
										>
											<span className="md:inline hidden pr-1">{trans("By")}</span>
											{ownerName}
										</a>
									</Link>
								)}

								{isMyLibraryEditorial && (
									<span>
										<Link legacyBehavior href={editUrl} prefetch={false}>
											<a className="hover:underline hover:text-black">{trans("Edit quiz")}</a>
										</Link>
									</span>
								)}
							</div>
						)}
						{isMyLibraryPage && (
							<div className="whitespace-nowrap overflow-ellipsis text-opacity-60 md:flex-row md:space-x-3 flex flex-col overflow-hidden font-bold text-black">
								<div className="flex flex-row text-black">
									<IconSlides className="inline-block w-4 h-4 mr-0.5" />
									{quiz?.slideCount}
								</div>
								<span className="lg:block hidden">{formatDate(quiz?.updatedAt)}</span>
							</div>
						)}
					</div>
				</div>
			</div>
			{props.children}
		</div>
	);
}
