import { useCallback, useRef, useEffect, useState } from "react";
import { useRouter } from "next/router";

import usePlaySessionStore from "@/stores/playSession";
import useWebSocketStore from "@/stores/webSocket";

export default function usePlayQuiz(quizId) {
	const router = useRouter();
	const routerRef = useRef(router);
	useEffect(() => void (routerRef.current = router), [router]);

	const setNewPlaySession = usePlaySessionStore((state) => state.setNewPlaySession);
	const setNewPlaySessionRef = useRef(setNewPlaySession);
	useEffect(() => void (setNewPlaySessionRef.current = setNewPlaySession), [setNewPlaySession]);

	const isHostingActive = useWebSocketStore((state) => state.connected);
	const isHostingActiveRef = useRef(isHostingActive);
	useEffect(() => void (isHostingActiveRef.current = isHostingActive), [isHostingActive]);

	const [playQuizId, setPlayQuizId] = useState(quizId);
	useEffect(() => void setPlayQuizId(quizId), [quizId]);
	const playQuizIdRef = useRef(playQuizId);
	useEffect(() => void (playQuizIdRef.current = playQuizId), [playQuizId]);

	return useCallback(() => {
		if (!isHostingActiveRef.current && setNewPlaySessionRef.current) {
			setNewPlaySessionRef.current(true);
		}

		if (routerRef.current && playQuizIdRef.current) {
			routerRef.current.push(`/play/${playQuizIdRef.current ? `${playQuizIdRef.current}/` : ""}`);
		}
	}, []);
}
