import { deleteData, getData, getData2, patchData, postData } from "@/api/helpers";

export async function apiEditorialByCategory(id = null, query = {}) {
	return await getData(`/editorial/category/${id ? id : ""}`, query);
}

export const apiEditorialByCategory2 = (id = null, query = {}) =>
	getData2(`/editorial/category/${id ? id : ""}`, query);

export async function apiEditorialBySimilarity(id = null) {
	return await getData(`/editorial/similarity/${id ? id : ""}`);
}

export const apiEditorialBySimilarity2 = (id = null, query = {}) =>
	getData2(`/editorial/similarity/${id ? id : ""}`, query);

export async function apiCreateEditorialSection(categoryId, { name, pos, type }) {
	return await postData(`/editorial/category/${categoryId}`, { name, pos, type });
}

export async function apiUpdateEditorialSection(sectionId, options) {
	return await patchData(`/editorial/section/${sectionId}`, options);
}

export async function apiDeleteEditorialSection(sectionId) {
	return await deleteData(`/editorial/section/${sectionId}`);
}

export async function apiGetEditorialSections(id = null) {
	return await getData(`/editorial/admin/category/${id || ""}`);
}
